import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import './CalculatorResponse.css';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import axios from 'axios';
import { getCookie } from '../utils/cookie';
import arrow from '../components/images/middle-arrow.jpg';
import LoadingIcon from '../components/LoadingIcon';


function CalculatorResponse({ score, estiScore, calculatorTimes,  CALCULATOR_TIMES}) {
  // const [score, setScore] = useState(null);
  const { user, dispatch } = useAuthContext();
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/subscribe");
  };

  {calculatorTimes === CALCULATOR_TIMES &&  !user.isPremium &&(
    <div className="cal_container">
      You have tried the Lifespan Calculator. Subscribe and try more
      features!
    </div>
  )}
  return (
    <div className="response" >
      <div className="introduction">
        <br></br>
        <div className="title">LifeSpan Estimate</div>
        <div className="text">
        We estimate your current lifespan based on your entries, then we predict what your lifespan could be based on AI modeling of many research reports and optimized recommendations, all available in our premium subscription, including numerous tools to help you achieve your goals.
        </div>
      </div>



      <div className='result'>
        <div style={{ width: '33.33%' }}>
          <div>Current Estimate</div>
          {!score ? (
            <div className="spinner-container">
              <div
                className="spinner"
                style={{ width: "4rem", height: "4rem", marginTop: "5px" }}
              ></div>
              <p>Loading data, please wait...</p>
            </div>
          ) : (

            <div
              className="number"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>{parseFloat(Math.min(score, 94)).toFixed(1)}</span>
              <span style={{ fontSize: "0.5em", marginLeft: "5px" }}>
                years
              </span>

            </div>
          )}
        </div>
        <div className="middle">
          <img
            src={arrow}
            alt="arrow"
            style={{ width: "60px", height: "55px" }}
          />
        </div>

        <div style={{ width: '33.33%' }}>
          <div>We can help you increase it to</div>
          {!estiScore ? (
            <div className="spinner-container">
              <div
                className="spinner"
                style={{ width: "4rem", height: "4rem", marginTop: "5px" }}
              ></div>
              <p>Loading data, please wait...</p>
            </div>
          ) : (

            <div className='number' style={{ display: 'flex', alignItems: 'center' }}>
              <span>{parseFloat(Math.min(estiScore, 94)).toFixed(1)}</span>
              <span style={{ fontSize: '0.5em', marginLeft: '5px' }}>years</span>
            </div>
          )}
        </div>

        
      </div>

      <div className="inlineContainer" style={{ marginTop: "30px" }}>
        <button
          type="submit"
          variant="dark"
          style={{ minWidth: "225px", height: "45px" }}
          disabled={calculatorTimes >= CALCULATOR_TIMES}
          // onClick={handleSubmit}
        >
          Submit
        </button>

        {calculatorTimes >= CALCULATOR_TIMES ? (
          <p style={{ color: "red", marginTop: "10px" }}>
            You have used up all 3 free attempts to calculate your lifespan
          </p>
        ) : (
          calculatorTimes > 0 && (
            <p style={{ marginTop: "10px" }}>
              You can calculate {CALCULATOR_TIMES - calculatorTimes} more {CALCULATOR_TIMES - calculatorTimes === 1 ? "time" : "times"}
            </p>
          )
        )}
      </div>

      
      {!user.isPremium &&(
              <div className='end' onClick={handleClick}>
              Subscribe for a personalized program to get you to your desired health
              goals
            </div>
      )}

    </div>
  );
}

export default CalculatorResponse;
