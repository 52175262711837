import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

const Chatbot = ({ size = 70, iconSize = 24, bottom = 175, right = 20 }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const messageEndRef = useRef(null);

  const { user } = useAuthContext();

  const CHAT_CACHE_KEY = "chatbotMessages";
  const CACHE_EXPIRY_KEY = "chatbotCacheExpiry";
  const CACHE_DURATION = 30 * 60 * 1000;

  const loadMessagesFromCache = () => {
    const cachedMessages = localStorage.getItem(CHAT_CACHE_KEY);
    const cacheExpiryTime = localStorage.getItem(CACHE_EXPIRY_KEY);

    if (cachedMessages && cacheExpiryTime) {
      const currentTime = new Date().getTime();
      if (currentTime - cacheExpiryTime < CACHE_DURATION) {
        return JSON.parse(cachedMessages);
      } else {
        localStorage.removeItem(CHAT_CACHE_KEY);
        localStorage.removeItem(CACHE_EXPIRY_KEY);
      }
    }
    return [];
  };

  const [messages, setMessages] = useState(loadMessagesFromCache());

  const saveMessagesToCache = (messages) => {
    localStorage.setItem(CHAT_CACHE_KEY, JSON.stringify(messages));
    localStorage.setItem(CACHE_EXPIRY_KEY, new Date().getTime().toString());
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    saveMessagesToCache(messages);
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isChatOpen]);

  const handleChatToggle = () => {
    setIsChatOpen((prev) => !prev);
  };

  if (!user?.userId) {
    return null;
  }

  const handleSendMessage = async () => {
    if (!user) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          text: "Please log in to use the chatbot.",
        },
      ]);
      setMessage("");
      return;
    }

    if (message.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: message.trim() },
      ]);
      setMessage("");
      setLoading(true);
      try {
        const response = await axios.post(
          "https://dummy-hosting-chatbot-api.onrender.com/api",
          {
            userId: user.userId,
            message: message.trim(),
          }
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: response.data.content,
          },
        ]);
      } catch (error) {
        console.error("Error sending message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: "Sorry, something went wrong.",
          },
        ]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!isChatOpen && (
        <button
          style={{
            position: "fixed",
            bottom: `${bottom}px`,
            right: `${right}px`,
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: "#007BFF",
            color: "white",
            borderRadius: "50%",
            border: "none",
            cursor: "pointer",
            fontSize: `${iconSize}px`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleChatToggle}
        >
          💬
        </button>
      )}

      {isChatOpen && (
        <div
          style={{
            position: "fixed",
            bottom: "80px",
            right: "20px",
            width: "24%",
            height: "60%",
            backgroundColor: "white",
            border: "1px solid #ddd",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "white",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Mety Chatbot (Beta)</span>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={handleChatToggle}
            >
              ✖
            </button>
          </div>
          <div
            style={{
              flex: "1",
              padding: "10px",
              overflowY: "auto",
              backgroundColor: "#f4f4f4",
            }}
          >
            <p>Hello! How can I assist you today?</p>
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  padding: "5px 10px",
                  margin: "5px 0",
                  alignSelf: msg.sender === "user" ? "flex-end" : "flex-start",
                  backgroundColor: msg.sender === "user" ? "#007BFF" : "#ddd",
                  color: msg.sender === "user" ? "white" : "black",
                  borderRadius: "10px",
                  maxWidth: "80%",
                  marginRight: msg.sender === "user" ? "10px" : "auto",
                  marginLeft: msg.sender === "user" ? "auto" : "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {msg.text}
              </div>
            ))}
            {loading && (
              <div
                style={{
                  alignSelf: "flex-start",
                  backgroundColor: "#ddd",
                  color: "black",
                  borderRadius: "10px",
                  maxWidth: "80%",
                  padding: "5px 10px",
                  marginRight: "auto",
                  marginLeft: "10px",
                }}
              >
                Responding...
              </div>
            )}
            <div ref={messageEndRef} />
          </div>
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <input
              type="text"
              value={message}
              placeholder="Type a message..."
              onChange={(e) => setMessage(e.target.value)}
              style={{
                padding: "10px",
                boxSizing: "border-box",
                width: "275px",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            />
            <button
              onClick={handleSendMessage}
              style={{
                padding: "10px 15px",
                width: "70px",
                backgroundColor: message.trim() ? "#007BFF" : "#ccc",
                color: "white",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                flexShrink: 0,
              }}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Chatbot;
