import React, { useState, useEffect } from "react";
import "./Footer.css";

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        (window.scrollY + window.innerHeight) /
        document.documentElement.scrollHeight;

      // Show the footer if scrolled 70% or more
      if (scrollPosition >= 0.6) {
        setVisible(true);

        // Clear existing timeout if scrolling continues
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // Set a new timeout to hide the footer after 8 seconds
        if (!hovered) {
          const newTimeoutId = setTimeout(() => {
            setVisible(false);
          }, 2000); // 2 seconds
          setTimeoutId(newTimeoutId);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId, hovered]);

  // Handle persistent visibility on hover
  const handleMouseEnter = () => {
    setHovered(true);
    setVisible(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
    // Set a timeout to hide after 8 seconds if not hovered
    const newTimeoutId = setTimeout(() => {
      setVisible(false);
    }, 8000);
    setTimeoutId(newTimeoutId);
  };

  return (
    <div
      className={`footer ${visible ? "visible" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ fontSize: "18px", fontWeight: "700" }}>Contact us</div>
      <div style={{ marginBottom: "4px" }}>
        For Information:{" "}
        <a href="mailto:info@myyouthspan.com">info@myyouthspan.com</a>
      </div>
      <div style={{ marginBottom: "16px" }}>
        For Application Support:{" "}
        <a href="mailto:support@myyouthspan.com">support@myyouthspan.com</a>
      </div>
      <div className="seperator"></div>
      <div>Copyright © METY Technology, Inc. 2024 - All Rights Reserved</div>
    </div>
  );
};

export default Footer;

