import React, { useState, useEffect } from "react";
import "./MessageArea.css";

const MessageArea = () => {
  const [messages, setMessages] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [numMessages, setNumMessages] = useState(1);

  const [scrollingUp, setScrollingUp] = useState(false);

  const baseURLAWS = process.env.REACT_APP_baseUrl

  useEffect(() => {
    const fetchData = async () => {
      try {
        // first get user id
        const userInfo = JSON.parse(localStorage.getItem('mysUser'))
        // Check if user is logged in
        if (!userInfo || !userInfo.userId) {
          // Set default message if not logged in
          setMessages([
            "Welcome to the Anti-aging Project! Subscribe for more advanced features!",
          ]);
          return;
        }
        // console.log(userInfo.userId)
        // Fetch data from API endpoint
        const response = await fetch(`${baseURLAWS}api/message-area/messages/${userInfo.userId}/`);
        const data = await response.json();
        const dataMessages = []

        for (let i = 0; i < data.length; i++) {
          dataMessages.push(data[i].body);
        }

        // Combine the test data and fetched data
        setMessages(dataMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, []);

  // Determine which messages to display based on the current index and number of messages
  // const displayedMessages = messages.slice(currentIndex, currentIndex + numMessages);
  const displayedMessages = messages

  // Update the index in a cyclic manner (vertically)
  useEffect(() => {
    if (messages.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % (messages.length));
      }, 5000); // the message frequency

      return () => clearInterval(intervalId);
    }
  }, [messages.length]);

  const isAtBottom =
    window.innerHeight + window.scrollY + 50 >= document.body.offsetHeight;

  return (
    <div className={`message-area ${isAtBottom ? "fixed-at-bottom" : ""}`}>
      <div className="message-container">
        {/* {displayedMessages.map((message, index) => (
          <div key={index} className="message">
            {message}
          </div>
        ))} */}
        <div className="message">
          {/* {
            displayedMessages.map((msg, index) => {
              return (
                <p key={index}> {msg} </p>
              ) 
            })
          } */}
            {displayedMessages}
        </div>
      </div>
    </div>
  );
};


export default MessageArea;
