import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useAuthContext } from '../hooks/useAuthContext';
import './Navbar.css';
import logoImage from './images/icon1.png';

function NavbarComponent({}) {
  let navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  //test
  const [show, setShow] = useState(false);
  // if (user){
  //   user.isPremium = true
  // }
  // console.log('user info: ', user)

  let handleLogout = () => {
    // make data in AuthContext NULL
    dispatch({ type: 'LOGOUT' });
    console.log('logout called');
    navigate('/');
    window.location.reload();
  };
  return (
    <>
      <nav className='navigation'>
        <Nav.Link
          href='/'
          className='navbar-brand'
          style={{ marginTop: '7.5px' }}
        >
          <img src={logoImage} width='45' height='45' alt='Logo' />
          {/* <Navbar.Brand href="/">SITE NAME</Navbar.Brand> */}
          {/* <img src={icon} alt="Logo" style={{width:"30px",height:"30px"}}/> */}
        </Nav.Link>

        <ul className='selection'>
          <li>
            <Nav.Link href='/calculator'>Lifespan Calculator</Nav.Link>
          </li>
          {user && user.isPremium && (
            <li>
              <Nav.Link href='/longevity-questionnaire'>My Profile</Nav.Link>
            </li>
          )}
          {user && user.isPremium && (
            <li>
              <Nav.Link href='/myplan'>My Plan</Nav.Link>
            </li>
          )}
          {user && user.isPremium && (
            <li>
              <Nav.Link href='/log'>My Log</Nav.Link>
            </li>
          )}
          {user && user.isPremium && (
            <li>
              <Nav.Link href='/progress'>My Progress</Nav.Link>
            </li>
          )}
          {user && user.isPremium && (
            <li>
              <Nav.Link href='/reports'>Reports</Nav.Link>
            </li>
          )}
          {user && user.isPremium && (
            <li>
              <Nav.Link href='/resources'>Resources</Nav.Link>
            </li>
          )}
          <li>
            <Nav.Link href='/about'>About</Nav.Link>
          </li>
          <li>
            <Nav.Link href='/subscribe'>Available Plans</Nav.Link>
          </li>
        </ul>

        <ul className='status'>
          {!user && (
            <>
              <li>
                <Nav.Link href='/signup'>Register</Nav.Link>
              </li>
              <li style={{ marginLeft: '15px' }}>
                <Nav.Link href='/signin'>Login</Nav.Link>
              </li>
            </>
          )}

          {user && (
            <>
              <li>
                {
                  <div className='nav_dropdown'>
                    <Nav.Link
                      className='nav_btn'
                      style={{
                        display: 'block',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'right',
                      }}
                    >
                      {user.firstName}
                    </Nav.Link>
                    {
                      <div className='nav_content'>
                        <Nav.Link href='/my-account' className='my_account'>
                          Account
                        </Nav.Link>
                      </div>
                    }
                  </div>
                }
              </li>
              <li style={{ marginLeft: '15px' }}>
                <button className='button' onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>

      {
        <ul className='phone phone_button'>
          <input class='phone_checkbox' type='checkbox' name='' id=''></input>
          <div class='hamburger-lines'>
            <span class='line line1'></span>
            <span class='line line2'></span>
            <span class='line line3'></span>
          </div>

          <div className='phone_menu_items'>
            <li>
              <Nav.Link href='/calculator'>Lifespan Calculator</Nav.Link>
            </li>
            {user && user.isPremium && (
              <li>
                <Nav.Link href='/longevity-questionnaire'>My Profile</Nav.Link>
              </li>
            )}
            <li>
              {user && user.isPremium ? (
                <Nav.Link href='/about'>My Plan</Nav.Link>
              ) : (
                <Nav.Link href='/subscribe'>Available Plans</Nav.Link>
              )}
            </li>
            {user && user.isPremium && (
              <li>
                <Nav.Link href='/log'>My Log</Nav.Link>
              </li>
            )}
            {user && user.isPremium && (
              <li>
                <Nav.Link href='/about'>My Progress</Nav.Link>
              </li>
            )}
            {user && user.isPremium && (
              <li>
                <Nav.Link href='/reports'>Reports</Nav.Link>
              </li>
            )}
            {user && user.isPremium && (
              <li>
                <Nav.Link href='/resources'>Resources</Nav.Link>
              </li>
            )}
            <li>
              <Nav.Link href='/about'>About</Nav.Link>
            </li>
          </div>
          <Nav.Link href='/' className='navbar-brand'>
            <img src={logoImage} width='45' height='45' alt='Logo' />
            {/* <Navbar.Brand href="/">SITE NAME</Navbar.Brand> */}
            {/* <img src={icon} alt="Logo" style={{width:"30px",height:"30px"}}/> */}
          </Nav.Link>

          {/*!show&&(<ul className="phone_option">
          <li>
            {(user && user.isPremium)? (
                <Nav.Link href="/longevity-questionnaire">My Profile</Nav.Link>
        
              ):(
                <Nav.Link href="/calculator">Lifespan Calculator</Nav.Link>)
              }
            
          </li>
          
          <li>
              {(user && user.isPremium)? (
                <Nav.Link href="/about">My Plan</Nav.Link>
        
              ):(
              <Nav.Link href="/subscribe">Available Plan</Nav.Link>)
              }
            </li>
         
            </ul>)*/}

          {!user && (
            <>
              <li>
                <Nav.Link href='/signup' style={{}}>
                  Register
                </Nav.Link>
              </li>
              <li>
                <Nav.Link href='/signin' style={{ marginLeft: '20px' }}>
                  Login
                </Nav.Link>
              </li>
            </>
          )}

          {user && (
            <>
              <li>
                {
                  <div className='nav_dropdown'>
                    <Nav.Link
                      className='nav_btn'
                      style={{
                        display: 'block',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'right',
                      }}
                    >
                      {user.firstName}
                    </Nav.Link>
                    {
                      <div className='nav_content'>
                        <Nav.Link href='/my-account' className='my_account'>
                          Account
                        </Nav.Link>
                      </div>
                    }
                  </div>
                }
              </li>
              <li>
                <button
                  className='button'
                  onClick={handleLogout}
                  style={{ marginLeft: '20px' }}
                >
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      }
    </>
  );
}

export default NavbarComponent;
