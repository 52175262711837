import React, { useState } from "react";
import axios from "axios";

import Chatbot from "../components/Chatbot";

const SearchBar = ({ onSearch, setArticles }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  const handleSearch = () => {
    setArticles([]);
    onSearch(searchTerm);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const inputStyle = {
    padding: "6px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  const buttonStyle = {
    backgroundColor: "#007bff",
    padding: "6px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "8px",
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchTerm}
          onChange={handleInputChange}
          style={inputStyle}
        />
        <button type="submit" style={buttonStyle}>
          Search
        </button>
      </form>
    </div>
  );
};

const SearchResultItem = ({ article }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const titleStyle = {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
  };

  const titleText = `${article.category} - ${article.subcategory}`;

  return (
    <div>
      <div onClick={toggleOpen} style={titleStyle}>
        <span>{titleText}</span>
        <span>{isOpen ? "▼" : "▶"}</span>
      </div>
      {isOpen && (
        <div style={{ padding: "10px" }}>
          <p>{article.blurb}</p>
          <p>
            <strong>Citation Links:</strong>
            <ul>
              {article.citation_links.map((link, index) => (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </p>
        </div>
      )}
    </div>
  );
};

const SearchResults = ({ articles }) => {
  return (
    <div style={{ width: "700px" }}>
      {articles.map((article, index) => (
        <div key={article.id}>
          <SearchResultItem article={article} />
          {index !== articles.length - 1 && (
            <hr
              style={{
                border: "1px solid #ccc",
                margin: "10px 0",
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const ResourcesPage = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStarted, setSearchStarted] = useState(false);

  const baseURL = process.env.REACT_APP_baseUrl;
  // const baseURL = "http://127.0.0.1:8000";
  //const baseURL = "https://api.myyouthspan.com";
  const searchURL = "/api/resourcecenter/search";

  const handleSearch = (searchTerm) => {
    setIsLoading(true);
    setSearchStarted(true);
    axios
      .get(baseURL + searchURL + `?query=${searchTerm}`)
      .then((response) => {
        setArticles(response["data"]["articles"]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error searching articles", error);
        setIsLoading(false);
      });
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "100px auto",
  };

  return (
    <div style={containerStyle}>
      <h5 style={{ marginBottom: "20px" }}>
        Learn more with our Resource Center!
      </h5>
      <SearchBar onSearch={handleSearch} setArticles={setArticles} />
      <div style={{ height: "30px" }} />
      {isLoading ? (
        <div>Searching...</div>
      ) : (
        <>
          {searchStarted && isLoading === false && articles.length === 0 ? (
            <div>No results found</div>
          ) : (
            <SearchResults articles={articles} />
          )}
        </>
      )}

      <Chatbot />
    </div>
  );
};

export default ResourcesPage;
